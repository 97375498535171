<template>
  <div class="about">
    <div class="head">
      <HeaderView :bgColor="bgColor"></HeaderView>
    </div>
    <div class="banner">
      <div class="banner_text">
          <h1>核心产品</h1>
          <p>携手伙伴共建数字化协作通路,助力产业数字化创建升级</p>
      </div>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      仓储平台
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/cc.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      供应链金融服务平台
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/gyljrfw.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      物联网监管平台
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/wlw.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      供应链管理平台
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/gylgl.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      互联网采购平台
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/hlw.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      互联网营销平台
      <div class="line"></div>
    </div>
    <div class="imgBox mar_bottom wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/coreProducts/hlwyx.png" alt="">
    </div>
  </div>
</template>
<script>
import HeaderView from '@/components/headerView.vue'
export default {
  name:'coreProducts',
  data(){
    return{
      // bgColor:'rgba(255,255,255,0)',
      bgColor:'',
    }
  },
  mounted(){
    new this.$wow.WOW().init({//新建实列
      boxClass: 'wow',//class名字
      // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
      offset: 0,//到元素距离触发动画（当默认为0）
      mobile: true,//在移动设备上触发动画（默认为true）
      live: true//对异步加载的内容进行操作（默认为true）
    })
  },
  components: {
    HeaderView
  },
}
</script>
<style scoped lang="less">
.banner{
  width:1920px;
  height:471px;
  position: relative;
  background: url('../assets/images/coreProducts/banner.png')no-repeat;
  background-size:100% 100%;
  .banner_text{
    width:1200px;
    margin:0 auto;
    padding-top:170px;
    padding-left:80px;
    text-align:left;
    color: #333333;
    animation: banner-animation 1s ease-in-out;
  }
  h1{
    height: 40px;
    font-size: 40px;
    margin-bottom:30px;
  }
  p{
    font-size: 20px;
  }
}
.imgBox{
  width:1920px;
  text-align:center;
  img{
    width:1200px;
  }
}
.mar_bottom{
  margin-bottom:70px;
}
</style>
