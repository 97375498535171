var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"head"},[_c('HeaderView',{attrs:{"bgColor":_vm.bgColor}})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner_text"},[_c('h1',[_vm._v("核心产品")]),_c('p',[_vm._v("携手伙伴共建数字化协作通路,助力产业数字化创建升级")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 仓储平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/cc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 供应链金融服务平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/gyljrfw.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 物联网监管平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/wlw.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 供应链管理平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/gylgl.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 互联网采购平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/hlw.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_title wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" 互联网营销平台 "),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox mar_bottom wow animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('img',{attrs:{"src":require("../assets/images/coreProducts/hlwyx.png"),"alt":""}})])
}]

export { render, staticRenderFns }